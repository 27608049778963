.carousel-container {
    width: 100%;
    height: 80vh;
    position: relative;
  }
  
  .carousel-bg {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
  }
  
  .carousel-image-wrapper {
    position: relative;
    width: 100%;
    height: 80vh;
  }
  
  .carousel-image {
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }
  
  .cs_hero {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
    text-align: center;
    width: 100%;
    z-index: 10;
  }
  
  .container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .cs_hero_text {
    color: white;
  }
  
  .cs_hero_title {
    font-size: 84px;
  }
  
  .cs_hero_subtitle {
    font-size: 20px;
  }

/* Laptop and larger screens */
@media (min-width: 1024px) {
    .cs_hero_title {
      font-size: 3vw; 
    }
  
    .cs_hero_subtitle {
      font-size: 1vw; 
    }
  }
  
  /* 1440p and larger screens */
  @media (min-width: 1440px) {
    .cs_hero_title {
      font-size: 2.5vw; 
    }
  
    .cs_hero_subtitle {
      font-size: 0.9vw; 
    }
  }
  
  /* Mobile screens */
  @media (max-width: 767px) {
    .cs_hero {
      transform: translate(-50%, -100%);
    }
  
    .cs_hero_title {
      font-size: 8vw; 
    }
  
    .cs_hero_subtitle {
      font-size: 3vw; 
    }
  }